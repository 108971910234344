<template>
  <div class="home">
    <div>
      <div class="container">
        <div class="limit mx-auto">
          <div class="row">
            <div class="row col-12 mx-auto presentation">
              <div class="col-12 col-lg-6 banner">
                <p class="title">{{this.text.name}}</p>
                <p class="sub-title">{{this.text.jobTitle}}<br>{{this.text.phoneNumber}}</p>
                <div @click="showVideo" class="button default d-flex d-lg-none">{{this.text.index.cover.fullVideo}}&nbsp;<i class=" icon fa-solid fa-angle-right"></i></div>
              </div>
            </div>
          </div>
          <aside class="video-container">
            <video class="mobileVideo" id="video" loop autoplay="autoplay" muted="muted">
              <source :src="`${this.publicPath}video/intro.mp4`" type="video/mp4">
            </video>
          </aside>
        </div>
      </div>
      <div class="services mt-5">
        <div class="col-10 mx-auto">
          <p class="title">{{this.text.index.services.title}}</p>
          <svg class="mx-auto" height="10" width="50">
            <line x1="10" y1="0" x2="200" y2="0" style="stroke:#b78f6d;stroke-width:4" />
          </svg>
        </div>
        <service-slider class="section"></service-slider>
      </div>

      <!-- ABOUT  -->
      <section class="wave section about-section">
        <div class="row mx-auto">
          <div class="col-12 col-lg-6">
            <div data-aos="fade-up" class="about-container">
                <div data-aos="fade-up" class="shape fill"></div>
                  <img class="profile-picture" :src="this.text.emily" :alt="this.text.name">
                <div class="shape empty"></div>
            </div>
          </div>
          <div class="col-12 col-lg-6 d-flex align-items-center justify-content-center">
            <div class="text-container">
              <p class="description intro" v-html="this.text.about.title"></p>
              <p class="description" v-html="this.text.about.introText"></p>
              <router-link :to="{name: 'about'}">
              <div class="button empty d-flex">{{ this.text.about.button }}&nbsp;<i class="icon fa-solid fa-angle-right"></i></div>
              </router-link>
            </div>
          </div>
        </div>
      </section>
      <div>
        <div class="wave-gradient-green"></div>
        <svg viewBox="0 0 500 150" preserveAspectRatio="xMinYMin meet">
          <path d="M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z" style="stroke: none; fill:#d8e2dc;"></path>
        </svg>
      </div>

      <!--Instagram feed-->
      <section class="section">
        <p class="title">{{ this.text.instagram.title }}</p>
        <svg class="mx-auto" height="50" width="50">
          <line x1="10" y1="0" x2="200" y2="0" style="stroke:#b78f6d;stroke-width:4" />
        </svg>
        <div class="row mx-auto">
          <div class="col-10 mx-auto">
            <div id="instagram-feed"><a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a></div>
            </div>
            <component :is="'script'">
              /* instagram-feed */
              (function(){
              var i,e,d=document,s="script";i=d.createElement("script");i.async=1;i.charset="UTF-8";
              i.src="https://cdn.curator.io/published/1917c296-e028-42fe-94f4-cd0b699087f1.js";
              e=d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
              })();
            </component>
          </div>
      </section>

      <!--Feedback-->
      <section class="wave section">
        <p class="title">{{ this.text.feedback.title }}</p>
        <svg class="mx-auto" height="50" width="50">
          <line x1="10" y1="0" x2="200" y2="0" style="stroke:#b78f6d;stroke-width:4" />
        </svg>
          <feedback-slider id="feedbackSlider"></feedback-slider>
      </section>
      <div>
        <svg viewBox="0 0 500 100" preserveAspectRatio="xMinYMin meet">
          <path d="M0.00,49.98 C149.99,150.00 349.20,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style="stroke: none; fill:#f3e4db;"></path>
        </svg>
        <div :style="{height: (this.$data.waveHeight - 120) + 'px'}" class="wave-gradient-pink"></div>
      </div>
    </div>
  </div>
</template>

<script>
import json from '../../text.json'
import ServiceSlider from "@/components/service-slider";
import FeedbackSlider from "@/components/feedback-slider";
export default {
  name: "home",

  components: {ServiceSlider, FeedbackSlider},
  data() {
    return {
      text: json,
      waveHeight: 100,
      aboutWaveHeight: 100,
      publicPath: '/'
    }
  },

  methods: {
    showVideo() {
      const element = document.getElementById('video');
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else {
        window.alert("Sorry your browser doesn't support fullscreen");
      }
    },
    onResize() {
      const feedbackSection = document.getElementById('feedbackSlider');
      if (feedbackSection != null) {
        this.$data.waveHeight = feedbackSection.offsetHeight;
      }
    }
  },

  updated() {
    this.onResize();
  },

  mounted() {
    this.onResize();
  },

  created() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }
}

</script>
