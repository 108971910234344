import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../Pages/home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "home" */ '../Pages/about.vue')
  },
  {
    path: '/services',
    name: 'services',
    component: () => import(/* webpackChunkName: "home" */ '../Pages/services.vue')
  },
  {
    path: '/service/:serviceName',
    name: 'service',
    component: () => import(/* webpackChunkName: "service" */ '../Pages/service.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: Home
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
