<template>
  <div class="service-slider row mx-auto">
    <div class="col-12 col-lg-10 col-xl-8 mx-auto">
      <carousel :items-to-show="this.display" :transition="400" :wrapAround="true" :autoplay="0">
        <slide v-for="(service, i) in services" :key="this.counter = i">
              <div class="card">
                <div class="card-body">
                  <div class="circular-image"><img :src="`${this.publicPath}${service.imageCircular}`" :alt="service.alt"></div>
                  <h5 class="card-title d-flex align-items-center justify-content-center">{{service.title}}</h5>
                  <p class="description" v-html="service.description"></p>
                  <router-link @click="scroolTop" :to="{ name: 'service', params: {serviceName: (service.title).replaceAll(' ', '-')}}">
                    <div class="button default">{{text.services.buttonTitle}} &nbsp;<i class="icon fa-solid fa-circle-chevron-right"></i></div>
                  </router-link>
                </div>
              </div>
        </slide>
        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
    </div>
  </div>
</template>

<script>

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import json from '../../text.json'



export default {
  name: "service-slider",

  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },

  data() {
    return {
      text: json,
      display: 0,
      counter: 0,
      services: [],
      publicPath: '/'
    }
  },

  methods: {
    onResize() {
      if (window.innerWidth > 992) {
        this.$data.display = 3
        this.lineWidth = 70
      } else if (window.innerWidth > 575) {
        this.$data.display = 2
        this.lineWidth = 100
      } else if (window.innerWidth < 575) {
        this.$data.display = 1
        this.lineWidth = 100
      }
    },

    scroolTop() {
      window.scrollTo(0, 0)
    }
  },

  created() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
    for (let cat of this.text.services.category) {
      for (let service of cat.items) {
        this.services.push(service)
      }
    }
  },
}
</script>

<style scoped>

</style>
