<template>
  <div class="feedback-slider row mx-auto">
    <div class="col-12 col-lg-10 mx-auto">
      <carousel :items-to-show="1" :transition="400" :wrapAround="true" :autoplay="8000">
        <slide  v-for="(feedback, i) in feedbackList.feedback.list" :key="i">
          <div class="col-12 col-lg-6 mx-auto">
            <div class="card">
              <div data-aos="fade-up" class="icon-container">
                <i class="icon fa-solid fa-quote-right"></i>
              </div>
              <div class="card-body d-flex align-items-center">
                <p v-html="feedback.description" class="description"></p>
              </div>
              <div class="counter-container">
                <p class="counter">{{(i+1)}}/{{feedbackList.feedback.list.length}}</p>
              </div>
            </div>
          </div>
          <div class="mx-auto d-lg-block d-none col-6">
            <div :data-aos="'zoom-in'" data-aos-anchor-placement="top-bottom">
              <picture>
                <source class="picture" :srcset="feedback.imageWebP" type="image/webp">
                <source class="picture" :srcset="feedback.image" type="image/jpg">
                <img class="picture" :src="feedback.image" :alt="feedback.alt">
              </picture>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from 'vue3-carousel';
import json from '../../text.json'

export default {
  name: "service-slider",

  components: {
    Carousel,
    Slide
  },

  data() {
    return {
      feedbackList: json,
      display: 0
    }
  }
}
</script>

<style scoped>

</style>
