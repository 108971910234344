<template>
  <div class="navigation-bar">
    <nav :class="{ 'navbar--hidden': !this.showNavbar && !this.$data.isMenuSelected }" :style="[this.$data.isMenuSelected ? [{'position': 'sticky'}, {'top': '0'}] : {}]" class="navbar navbar-expand-lg bg-light">
      <router-link to="/"><img class="logo" :src="`${this.publicPath}img/LogoDog.png`" alt="logo"></router-link>

      <div @click="hamMenu" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <div @click="hamMenuButton" :class="this.$data.isMenuSelected === true ? 'd-none': ''" >
          <i class="fa-solid fa-bars"></i>
        </div>
        <div @click="hamMenuButton" :class="this.$data.isMenuSelected === false ? 'd-none': ''" >
          <i class="fa-solid fa-xmark"></i>
        </div>
      </div>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li @click="hamMenu" :data-toggle="this.$data.isHamMenuVisible ? 'collapse' : ''" data-target="#navbarSupportedContent" class="nav-item">
            <router-link class="nav-link" :class="{'current': currentRoute === 'services'}" to="/services">{{ this.text.navbar.services }}</router-link>
          </li>
          <li @click="hamMenu" :data-toggle="this.$data.isHamMenuVisible ? 'collapse' : ''" data-target="#navbarSupportedContent" class="nav-item">
            <router-link class="nav-link" :class="{'current': currentRoute === 'about'}" to="/about">{{ this.text.navbar.about }}</router-link>
          </li>
          <li @click="hamMenu" :data-toggle="this.$data.isHamMenuVisible ? 'collapse' : ''" data-target="#navbarSupportedContent" class="nav-item">
            <a href="#Contact" class="nav-link">{{ this.text.navbar.contact }}</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import {useRoute} from "vue-router";
import {watch} from "vue";
import json from '../../text.json'

export default {
  name: "navBar",

  data() {
    return {
      text: json,
      currentRoute: '',
      currentRoutePath: '',
      isMenuSelected: false,
      isHamMenuVisible: false,
      showNavbar: true,
      publicPath: '/',
      lastScrollPosition: 0
    }
  },

  methods: {
    hamMenuButton() {
      this.$data.isHamMenuVisible = true;
    },
    hamMenu() {
      this.$data.isMenuSelected = !this.$data.isMenuSelected;
    },
    onScrollNavBar () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0) {
        return
      }
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 100) {
        return
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition
      this.lastScrollPosition = currentScrollPosition
    }
  },

  mounted() {
    window.addEventListener('scroll', this.onScrollNavBar);
    const route = useRoute();
    watch(() => route.name, () => {
      this.$data.currentRoute = route.name;
      this.$data.currentRoutePath = route.path;
    });
  }
}
</script>

<style scoped>

</style>
