<template>
  <footer id="Contact" class="footer">
    <div class="container">
      <div class="row footer-style text-left">
        <div class="offset-md-1 col-9 row">
          <div class="col-12 col-md-6">
            <p style="font-weight: bold; font-size: 25px" class="mt-4">{{this.text.footer.title}}</p>
            <ul>
              <li>
                <i class="icon fa-solid fa-envelope icon"></i>
                <a :href="`mailto:${this.text.email}`">&nbsp;{{this.text.email}}</a>
              </li>
              <li>
                <i class="fa-solid fa-phone"></i>
                <a :href="`tel:${this.text.phoneNumber}`">&nbsp;{{this.text.phoneNumber}}</a>
              </li>
              <li>
                <i class="icon fa-solid fa-location-dot"></i>
                <p>&nbsp;{{this.text.services.location}}</p>
              </li>
              <ul class="d-sm-flex d-inline-block align-items-center">
                <a class="instagram" :href="this.text.socials.instagram"><i class="fa-brands fa-instagram"></i></a>
                <a class="facebook" :href="this.text.socials.facebook" ><i class="fa-brands fa-facebook"></i></a>
              </ul>
            </ul>
          </div>
        </div>
        <div class="col-1 d-none d-md-inline-block">
          <i class="footer-icon fa-solid fa-paw"></i>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import json from '../../text.json'
export default {
  name: "myFooter",

  data() {
    return {
      text: json
    }
  },
}
</script>

<style scoped>

</style>
