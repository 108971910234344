<template>
  <nav-bar></nav-bar>
  <router-view/>
  <myFooter></myFooter>
</template>

<script>

import navBar from "@/components/navBar";
import myFooter from "@/components/myFooter";
import 'aos/dist/aos.css'
import AOS from "aos";
export default {
  name: 'MainPage',

  components: {navBar, myFooter},

  data() {
    return {
      currentRoute: ''
    }
  },

  mounted() {
    AOS.init({once: true});
    console.log('Hello World -- Made by VAD Production');
  }
};

</script>

<style lang="scss">
@import 'Style/main';
</style>
